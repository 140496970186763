import React from "react"

const AdModalPreDownload = () => {
  React.useEffect(() => {
    let script = document.createElement("script")
    script.src = "https://jsc.mgid.com/f/r/freedsoundmp3.com.751389.js"
    script.async = true
    script.onload = () => console.log("script loaded")
    document.body.appendChild(script)
  }, [])

  return (
    <div id="M493572ScriptRootC751389">
      <div id="M493572PreloadC751389"></div>
    </div>
  )
}

export default AdModalPreDownload
