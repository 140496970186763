import React, { useContext, useEffect } from "react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import { isMobile } from "react-device-detect"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SocialShareHome from "../components/SocialShare/SocialShareHome"

import AdHorizontalHome from "../components/Ads/AdHorizontalHome"

import SearchInput from "../components/SearchInput/SearchInput"
import SearchResults from "../components/Search/Search"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import { useQueryParam, StringParam } from "use-query-params"

const useStyles = makeStyles(theme => ({
  hero: {
    background: theme.palette.primary.main,
    color: "#FFF",
    textAlign: "center",
    padding: "2rem 0 2.7rem 0",

    [theme.breakpoints.up("md")]: {
      padding: "3.7rem 0rem 3.7rem 0",
    },
  },
  title: {
    fontSize: 26,
    lineHeight: 1.4,
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 30,
    fontWeight: 400,

    [theme.breakpoints.up("md")]: {
      fontSize: 40,
      marginBottom: 40,
    },
  },
  subtitle: {
    fontWeight: 400,
    marginTop: 30,
    fontSize: 17,
  },

  adLink: {
    color: "#FFF",
    fontSize: 15,
  },
}))

export default function IndexPage() {
  const { t } = useTranslation()
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const [queryParamQuery, setQueryParamQuery] = useQueryParam("q", StringParam)
  const [queryParamDownloadId, setQueryParamDownloadId] = useQueryParam(
    "d",
    StringParam
  )
  const [queryParamFormat, setQueryParamFormat] = useQueryParam(
    "format",
    StringParam
  )
  const classes = useStyles()

  useEffect(() => {
    // query present at start
    if (queryParamQuery && !queryParamDownloadId) {
      dispatch({ type: "SEARCH", payload: { keyword: queryParamQuery } })
    }

    // download present at start
    if (queryParamDownloadId) {
      dispatch({
        type: "SEARCH_RELEVANT",
        payload: { id: queryParamDownloadId },
      })
    }
  }, [])

  return (
    <Layout>
      <SEO title={t("title")} description={t("description")} />

      {!state.searchedKeyword && (
        <section className={classes.hero}>
          <Container>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} sm={9} md={8} lg={7}>
                {/*  <Logo style={{ width: "100%", maxWidth: 480 }} />*/}

                <h1 className={classes.title}>
                  <Trans>hero-title</Trans>
                </h1>
                <SearchInput />

                <h2 className={classes.subtitle}>
                  <Trans>hero-subtitle</Trans>
                </h2>

                <div style={{ marginTop: 3, marginBottom: 7 }}>
                  <SocialShareHome />
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
      )}

      <Container maxWidth="md">
        {state.searchedKeyword && <SearchResults />}

        <div style={{ marginTop: 40 }}>
          <AdHorizontalHome />
        </div>
      </Container>

      <Container maxWidth="md">
        <div style={{ padding: 12 }}>
          <Grid
            container
            spacing={6}
            style={{ marginTop: 10, textAlign: "center" }}
          >
            <Grid item xs={12} md={4}>
              <h2>
                <Trans>feature1Title</Trans>
              </h2>
              <p>
                <Trans>feature1Text</Trans>
              </p>
            </Grid>
            <Grid item xs={12} md={4}>
              <h2>
                <Trans>feature2Title</Trans>
              </h2>
              <p>
                <Trans>feature2Text</Trans>
              </p>
            </Grid>
            <Grid item xs={12} md={4}>
              <h2>
                <Trans>feature3Title</Trans>
              </h2>
              <p>
                <Trans>feature3Text</Trans>
              </p>
            </Grid>
          </Grid>
        </div>

        <div className="text-center" style={{ marginTop: 80 }}>
          <h3>
            <Trans>homeTitle1</Trans>
          </h3>
          <p>
            <Trans>homeText1</Trans>
          </p>
          <p>
            <Trans>homeText2</Trans>
          </p>
          <p>
            <Trans>homeText3</Trans>
          </p>
        </div>
      </Container>
    </Layout>
  )
}
