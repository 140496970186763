import React, { useContext, useEffect } from "react"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Grid from "@material-ui/core/Grid"
import DialogDownload from "../Dialogs/DialogDownload"
import DialogPreDownload from "../Dialogs/DialogPreDownload"
import ButtonDownload from "../Buttons/ButtonDownload"
import TimeIcon from "@material-ui/icons/AccessTime"
import AdHorizontal from "../Ads/AdHorizontal"
import PlayIcon from "@material-ui/icons/PlayArrow"
import ParseHtml from "react-html-parser"
import { isMobile } from "react-device-detect"
import IconButton from "@material-ui/core/IconButton"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"

import Hidden from "@material-ui/core/Hidden"

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: 20,
    border: 0,
    marginBottom: 1,
    boxShadow: "0px 2px 14px rgba(32,33,36,0.12)",
    transition: "all .25s ease-in-out",
  },

  cardExpand: {
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 40,
    marginBottom: 30,
    boxShadow: "0px 2px 14px rgba(32,33,36,0.32)",
  },

  cardContent: {
    padding: "16px !important",
    [theme.breakpoints.down("sm")]: {
      padding: "13px !important",
    },
  },

  media: {
    height: 80,
    width: 80,
    borderRadius: 6,
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      height: 64,
      width: 64,
    },
  },

  mediaAction: {
    display: "none",
    background: "rgb(0 0 0 / 45%)",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    borderRadius: 5,
  },

  mediaIcon: {
    color: "#FFF",
    fontSize: 30,
  },
  title: {
    fontSize: 17,
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
    "& span": {
      fontSize: "0.94rem",
      color: "rgb(117, 117, 117)",
    },
  },
  duration: {
    fontSize: 13,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
    },
  },
  details: {
    display: "flex",
    alignItems: "center",

    "&:hover #media-action": {
      display: "flex",
    },
  },
}))

export const formatTitle = title => {
  title = title.replace("u0027", "'").replace("u00e5", "a")

  const regExp = /\(([^)]+)\)/
  const matches = regExp.exec(title)

  matches &&
    matches.map(item => {
      title = title.replace("(" + item + ")", "<span>(" + item + ")</span>")
    })

  return ParseHtml(title)
}

export const SearchResult = props => {
  const classes = useStyles()
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const [downloadFormat, setDownloadFormat] = React.useState("")

  const [modalPreDownloadOpen, setModalPreDownloadOpen] = React.useState(false)
  const [modalDownloadOpen, setModalDownloadOpen] = React.useState(false)

  const handleModalPreDownloadClose = value => {
    setModalPreDownloadOpen(false)
  }

  const handleModalDownloadClose = value => {
    setModalDownloadOpen(false)
    setDownloadFormat("")
  }

  //on format change ->start download dialog
  useEffect(() => {
    if (downloadFormat) setModalDownloadOpen(true)
  }, [downloadFormat])

  const handleCardClick = () => {
    console.log("hssa1")
    if (isMobile) {
      console.log("hssa2")
      setModalPreDownloadOpen(true)
    }
  }

  return (
    <div>
      {props.index === 2 && (
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <AdHorizontal />
          </CardContent>
        </Card>
      )}

      {/*props.index === 10 && (
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            AdHorizontal alt
          </CardContent>
        </Card>
      )*/}

      <Card
        onClick={handleCardClick}
        className={`${classes.card} ${
          state.relevantSearchId === props.id ? classes.cardExpand : ""
        }`}
      >
        <CardContent className={classes.cardContent}>
          <Grid container spacing={3} alignItems="center" alignContent="center">
            <Grid item xs className={classes.details}>
              <div>
                <CardMedia
                  className={classes.media}
                  image={`https://i.ytimg.com/vi/${props.id}/hqdefault.jpg`}
                  title={props.title}
                >
                  <Hidden smDown>
                    <CardActionArea
                      onClick={() => {
                        dispatch({ type: "PLAY", payload: { id: props.id } })
                      }}
                      id="media-action"
                      className={classes.mediaAction}
                    >
                      <PlayIcon className={classes.mediaIcon} />
                    </CardActionArea>
                  </Hidden>
                </CardMedia>
              </div>

              <div>
                <Typography
                  className={classes.title}
                  gutterBottom
                  component="h2"
                >
                  {formatTitle(props.title)}
                </Typography>

                {props.duration && (
                  <Typography
                    className={classes.duration}
                    color="textSecondary"
                    component="p"
                  >
                    <TimeIcon style={{ fontSize: 13, marginRight: 4 }} />
                    {props.duration}
                  </Typography>
                )}
              </div>
            </Grid>

            <Grid item>
              <Hidden mdUp>
                <IconButton
                  onClick={() => setModalPreDownloadOpen(true)}
                  aria-label="download"
                >
                  <ChevronRightIcon />
                </IconButton>
              </Hidden>

              <Hidden smDown>
                <ButtonDownload
                  format="mp3"
                  onClick={() => setDownloadFormat("mp3")}
                  style={{ marginRight: 15 }}
                />

                <ButtonDownload
                  format="mp4"
                  onClick={() => setDownloadFormat("mp4")}
                />
              </Hidden>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <DialogPreDownload
        open={modalPreDownloadOpen}
        onClose={handleModalPreDownloadClose}
        title={props.title}
        id={props.id}
        duration={props.duration}
      />

      <DialogDownload
        open={modalDownloadOpen}
        onClose={handleModalDownloadClose}
        title={props.title}
        id={props.id}
        duration={props.duration}
        format={downloadFormat}
      />
    </div>
  )
}

export default SearchResult
