import React, { useEffect } from "react"
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"
import AdModalPreDownload from "../Ads/AdModalPreDownload"
import YouTube from "react-youtube-embed"
import ButtonDownload from "../Buttons/ButtonDownload"
import DialogDownload from "../Dialogs/DialogDownload"
import _ from "lodash"
import Slide from "@material-ui/core/Slide"
import SocialShare from "../SocialShare/SocialShare"

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    fontSize: 17,
  },
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

export default function DialogPreDownload(props) {
  const [downloadFormat, setDownloadFormat] = React.useState("")
  const [modalDownloadOpen, setModalDownloadOpen] = React.useState(false)

  const { onClose, open } = props

  const handleModalDownloadClose = value => {
    setModalDownloadOpen(false)
    setDownloadFormat("")
    handleClose()
  }

  const handleClose = () => {
    onClose()
  }

  //on format change ->start download dialog
  useEffect(() => {
    if (downloadFormat) setModalDownloadOpen(true)
  }, [downloadFormat])

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open && !downloadFormat}
        TransitionComponent={Transition}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {_.truncate(props.title)}
        </DialogTitle>
        <DialogContent dividers style={{ padding: 0 }}>
          {/*  <YouTube id={props.id} />*/}

          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              class="embed-responsive-item"
              frameBorder="0"
              src={`https://youtube-iframe-63c85.web.app/?id=${props.id}`}
            ></iframe>
          </div>

          <div style={{ padding: 15, paddingTop: 7, textAlign: "center" }}>
            <div style={{ marginTop: 0, marginBottom: 7 }}>
              <SocialShare id={props.id} title={props.title} />
            </div>

            <ButtonDownload
              format="mp3"
              onClick={() => setDownloadFormat("mp3")}
              style={{ marginBottom: 10, width: "100%" }}
            />

            <ButtonDownload
              format="mp4"
              onClick={() => setDownloadFormat("mp4")}
              style={{ marginBottom: 10, width: "100%" }}
            />

            <div style={{ marginTop: 6 }}>
              {" "}
              <AdModalPreDownload />
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <DialogDownload
        open={modalDownloadOpen}
        onClose={handleModalDownloadClose}
        title={props.title}
        id={props.id}
        duration={props.duration}
        format={downloadFormat}
      />
    </div>
  )
}
