import React, { useContext, useEffect } from "react"
import { GlobalStateContext } from "../../context/GlobalContextProvider"
import { Trans } from "gatsby-plugin-react-i18next"
import axios from "axios"
import CircularProgress from "@material-ui/core/CircularProgress"
import Alert from "@material-ui/lab/Alert"
import SearchResult from "./SearchResult"
import { makeStyles } from "@material-ui/core/styles"
import { navigate } from "@reach/router"

const useStyles = makeStyles(theme => ({
  results: {
    marginTop: 30,
    [theme.breakpoints.down("sm")]: {
      marginTop: 60,
    },
  },
  card: {
    marginTop: 20,
    border: 0,
    marginBottom: 1,
    boxShadow: "0px 2px 14px rgba(32,33,36,0.12)",
    transition: "all .25s ease-in-out",
  },
}))

export default function Search(props) {
  const classes = useStyles()
  const state = useContext(GlobalStateContext)

  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [results, setResults] = React.useState([])
  const [emptyResults, setEmptyResults] = React.useState(false)

  useEffect(() => {
    if (props.query) {
      searchFirebase(props.query)

      typeof window !== "undefined" &&
        window.gtag &&
        window.gtag("event", "search", {
          event_category: "Search",
          event_label: props.query,
        })
    }
  }, [props.query])

  useEffect(() => {
    if (state.searchedKeyword) {
      navigate("?q=" + state.searchedKeyword)
      searchFirebase(state.searchedKeyword)
    }
  }, [state.searchedKeyword])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [results])



  const searchFirebase = query => {
    console.log("s2..", query)

    setLoading(true)
    setError(false)
    setEmptyResults(false)

    axios
      .get("https://europe-west1-freedsoundmp3.cloudfunctions.net/search/", {
        //.get("http://localhost:5001/freedsoundmp3/europe-west1/search", {
        params: {
          query: query,
        },
      })
      .then(res => {
        console.log("s2 res", res)

        //err
        if (!res || !res.data || res.error) {
          searchErrorEvent("no data or error", 2)
          searchRapidApi(query)
          setLoading(false)
          //setError(true)
          return
        }
        //no results
        if (res.data.length === 0) {
          searchErrorEvent("empty", 2)
          setLoading(false)
          setEmptyResults(true)
          return
        }

        const updatedResults = res.data.map(item => {
          return {
            ...item,
            duration: secondsToHms(item.duration)
          };
        });



        setResults(updatedResults)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        //setError(true)
        setLoading(false)
        searchRapidApi(query)
        searchErrorEvent("fail api catch", 2)
      })
  }

  // SEARCH RAPID API

  const searchRapidApi = query => {
    console.log("s3..", query)

    setLoading(true)
    setError(false)
    setEmptyResults(false)

    axios
      .get(
        "https://europe-west1-freedsoundmp3.cloudfunctions.net/searchRapidApi",
        {
          params: {
            query: query,
          },
        }
      )
      .then(res => {
        console.log("s3 res", res)

        //err
        if (!res.data || res.data.error) {
          // call second method
          searchErrorEvent("no data or error", 3)
          setLoading(false)
          setError(true)

          return
        }

        //no results
        if (res.data.contents.length === 0) {
          searchErrorEvent("empty", 3)
          setEmptyResults(true)
          return
        }

        const cleanResults = res.data.contents.map(item => {
          return {
            id: item.video.videoId,
            title: item.video.title,
            duration: item.video.lengthText,
          }
        })

        //console.log("cleanResults", cleanResults)

        setResults(cleanResults)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
        setError(true)
        searchErrorEvent("fail api catch", 3)
      })
  }

  const searchErrorEvent = (error, searchMethod) => {
    typeof window !== "undefined" &&
      window.gtag &&
      window.gtag("event", "Search", {
        event_category: "Error Search " + searchMethod,
        event_label: error,
      })
  }

  return (
    <div className={classes.results}>
      {(loading || emptyResults || error) && (
        <div style={{ textAlign: "center", marginTop: 60, marginBottom: 65 }}>
          {loading && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress color="primary" />
            </div>
          )}

          {!loading && emptyResults && (
            <Alert variant="filled" severity="info">
              <Trans>No Results</Trans> 😕
            </Alert>
          )}

          {!loading && error && !emptyResults && (
            <Alert variant="filled" severity="error">
              <Trans>searchError</Trans>
            </Alert>
          )}
        </div>
      )}

      {!loading &&
        results.length > 0 &&
        results.map(function (item, index) {
          return (
            <div key={item.id || index}>
              <SearchResult {...item} index={index} />
            </div>
          )
        })}
    </div>
  )
}


/* format duration */
function secondsToHms(seconds) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor(seconds % 3600 / 60);
  const s = Math.floor(seconds % 3600 % 60);

  const hDisplay = h > 0 ? (h > 9 ? h : '0' + h) + ':' : '';
  const mDisplay = m > 9 ? m : '0' + m;
  const sDisplay = s > 9 ? s : '0' + s;

  return hDisplay + mDisplay + ':' + sDisplay;
}