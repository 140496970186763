import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"
import DownloadIcon from "@material-ui/icons/GetApp"

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

export default function DialogDownload(props) {
  return (
    <Button
      {...props}
      variant="contained"
      size="medium"
      color="primary"
      startIcon={<DownloadIcon />}
    >
      {props.format ? props.format : "Download"}
    </Button>
  )
}
