import React from "react"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import { useTranslation } from "gatsby-plugin-react-i18next"
import ShareIcon from "@material-ui/icons/Share"
import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import CloseIcon from "@material-ui/icons/Close"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import FacebookIcon from "@material-ui/icons/Facebook"
import TwitterIcon from "@material-ui/icons/Twitter"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import CheckIcon from "@material-ui/icons/Check"
import { isMobile } from "react-device-detect"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { TwitterShareButton, EmailShareButton } from "react-share"

const useStyles = makeStyles(theme => ({
  dialog: {},
  dialogContent: {
    paddingBottom: 40,
  },
  btnShare: {
    marginTop: 0,
    padding: 10,
  },
  dialogTitle: {
    "& h2": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  closeButton: {
    float: "right",
  },
  appBar: {
    boxShadow: "none",
    borderBottom: "1px solid #DDD",
  },

  btnSocial: {
    border: "2px solid #CFCFCF",
    background: "#fff",
    width: 60,
    height: 60,
    borderRadius: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },

    [theme.breakpoints.down("sm")]: {
      width: 50,
      height: 50,
    },
  },
  btnCopyClipboard: {
    fontSize: 10,
    float: "right",
  },

  textInput: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
}))

export default function SocialShare(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [copied, setCopied] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  const checkWhatsapp = e => {
    e.preventDefault()
    let mobileShare = `whatsapp://send?text=${shareTitle}%20${shareURL}`
    let desktopShare = `https://web.whatsapp.com/send?text=${shareTitle}%20${shareURL}`

    if (isMobile) {
      window.open(mobileShare)
    } else {
      window.open(desktopShare)
    }
  }

  const handleClickOpen = e => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const shareURL = `https://freedsound.live?d=${props.id}`
  const shareTitle = `Freedsound - ${t("seoKeywordsTextTop").toLowerCase()} - ${props.title
    }`
  const shareMailSubject = `${t("seoKeywordsTextTop").toLowerCase()} - ${props.title
    }`

  const handleStringCopied = () => {
    setCopied(true)
  }

  const handleWebApiShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Freedsound",
          text: shareTitle,
          url: shareURL,
        })
        .then(() => {
          //console.log("Thanks for sharing!")
        })
        .catch(err => {
          //console.log(`Couldn't share because of`, err.message)
        })
    } else {
      //console.log("web share not supported")
      return false
    }
  }

  return (
    <div>
      <Button
        fullWidth
        variant="text"
        className={classes.btnShare}
        size="small"
        aria-label="share"
        onClick={navigator.share ? handleWebApiShare : handleClickOpen}
        startIcon={<ShareIcon style={{ fontSize: 14 }} />}
      >
        {t("share")}
      </Button>
      <Dialog
        maxWidth="xs"
        fullWidth
        className={classes.dialog}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-share"
      >
        <DialogTitle id="alert-dialog-share" className={classes.dialogTitle}>
          {t("share")}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <p style={{ marginBottom: 20 }}>
            {t("Share this content on your favorite social networks")}
          </p>

          <div style={{ marginBottom: 20 }}>
            <OutlinedInput
              fullWidth
              rows={1}
              value={shareURL}
              variant="outlined"
              className={classes.textInput}
              endAdornment={
                <InputAdornment position="end">
                  <CopyToClipboard text={shareURL} onCopy={handleStringCopied}>
                    <IconButton
                      aria-label="copy"
                      className={classes.btnCopyClipboard}
                    >
                      {!copied ? (
                        <FileCopyIcon style={{ fontSize: 16 }} />
                      ) : (
                        <CheckIcon style={{ fontSize: 16, color: "green" }} />
                      )}
                    </IconButton>
                  </CopyToClipboard>
                </InputAdornment>
              }
            />
          </div>

          <div>
            <Grid
              container
              direction="row"
              spacing={2}
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <EmailShareButton
                  className={classes.btnSocial}
                  url={shareURL}
                  subject={shareMailSubject}
                  body={shareTitle}
                  resetButtonStyle={false}
                  style={{ cursor: "pointer" }}
                >
                  <MailOutlineIcon style={{ color: "#999" }} />
                </EmailShareButton>
              </Grid>

              <Grid item>
                <a
                  href={`https://facebook.com/sharer/sharer.php?u=${shareURL}&quote=${shareTitle}`}
                  target="_blank"
                  rel="noreferrer"
                  aria-label=""
                  className={classes.btnSocial}
                >
                  <FacebookIcon style={{ color: "#3B65A8" }} />
                </a>
              </Grid>

              <Grid item>
                <TwitterShareButton
                  className={classes.btnSocial}
                  url={shareURL}
                  title={shareTitle}
                  resetButtonStyle={false}
                  style={{ cursor: "pointer" }}
                >
                  <TwitterIcon style={{ color: "#00A9ED" }} />
                </TwitterShareButton>
              </Grid>
              <Grid item>
                <a
                  className={classes.btnSocial}
                  onClick={checkWhatsapp}
                  href="#"
                  target="_blank"
                  rel="noopener"
                  aria-label="whatsapp"
                >
                  <WhatsAppIcon style={{ color: "#25d366" }} />
                </a>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
