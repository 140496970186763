import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"
import AdModalPreDownload from "../Ads/AdModalPreDownload"
import Download from "../Download/Download"
import { formatTitle } from "../Search/SearchResult"
import Slide from "@material-ui/core/Slide"
import SocialShare from "../SocialShare/SocialShare"

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

export default function DialogDownload(props) {
  const { onClose, open } = props

  const handleClose = () => {
    onClose()
  }
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
      >
        {props.format && props.title && (
          <div>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              Download {props.format}
            </DialogTitle>
            <DialogContent dividers className="text-center">
              <h4
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                  marginTop: 3,
                  marginBottom: 0,
                  padding: 5,
                }}
              >
                {formatTitle(props.title)}
              </h4>

              <div style={{ marginTop: 3, marginBottom: 7 }}>
                <SocialShare id={props.id} title={props.title} />
              </div>

              <Download
                format={props.format}
                id={props.id}
                title={props.title}
              />

              <div style={{ marginTop: 30 }}>
                <AdModalPreDownload />
              </div>
            </DialogContent>
          </div>
        )}
      </Dialog>
    </div>
  )
}
