import React from "react"

const AdHorizontal = () => {
  React.useEffect(() => {
    var D = new Date(),
      d = document,
      b = "body",
      ce = "createElement",
      ac = "appendChild",
      st = "style",
      ds = "display",
      n = "none",
      gi = "getElementById",
      lp = d.location.protocol,
      wp = lp.indexOf("http") == 0 ? lp : "https:"
    var i = d[ce]("iframe")
    i[st][ds] = n
    d[gi]("M383323ScriptRootC384002")[ac](i)
    try {
      var iw = i.contentWindow.document
      iw.open()
      iw.writeln("<ht" + "ml><bo" + "dy></bo" + "dy></ht" + "ml>")
      iw.close()
      var c = iw[b]
    } catch (e) {
      var iw = d
      var c = d[gi]("M383323ScriptRootC384002")
    }
    var dv = iw[ce]("div")
    dv.id = "MG_ID"
    dv[st][ds] = n
    dv.innerHTML = 384002
    c[ac](dv)
    var s = iw[ce]("script")
    s.async = "async"
    s.defer = "defer"
    s.charset = "utf-8"
    s.src =
      wp +
      "//jsc.mgid.com/f/r/freedsound.io.384002.js?t=" +
      D.getYear() +
      D.getMonth() +
      D.getUTCDate() +
      D.getUTCHours()
    c[ac](s)
  }, [])

  return (
    <div id="M383323ScriptRootC384002">
      <div id="M383323PreloadC384002"></div>
    </div>
  )
}

export default AdHorizontal
