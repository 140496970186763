import React from "react"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import { useTranslation } from "gatsby-plugin-react-i18next"
import ShareIcon from "@material-ui/icons/Share"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import CloseIcon from "@material-ui/icons/Close"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import FacebookIcon from "@material-ui/icons/Facebook"
import TwitterIcon from "@material-ui/icons/Twitter"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import { isMobile } from "react-device-detect"
import { TwitterShareButton, EmailShareButton } from "react-share"

const useStyles = makeStyles(theme => ({
  dialog: {},
  dialogContent: {
    paddingBottom: 40,
  },
  btnShare: {
    marginTop: 0,
    padding: "10px 20px",
    color: "#FFF",
  },

  closeButton: {
    float: "right",
  },

  btnSocial: {
    border: "2px solid #CFCFCF",
    background: "#fff",
    width: 60,
    height: 60,
    borderRadius: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },

    [theme.breakpoints.down("sm")]: {
      width: 50,
      height: 50,
    },
  },
}))

export default function SocialShareHome(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [copied, setCopied] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  const checkWhatsapp = e => {
    e.preventDefault()
    let mobileShare = `whatsapp://send?text=${shareTitle}%20${shareURL}`
    let desktopShare = `https://web.whatsapp.com/send?text=${shareTitle}%20${shareURL}`

    if (isMobile) {
      window.open(mobileShare)
    } else {
      window.open(desktopShare)
    }
  }

  const handleClickOpen = e => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const shareURL = `https://freedsound.live`
  const shareTitle = `Freedsound - ${t("hero-title")}`
  const shareMailSubject = `${t("hero-title")}`

  const handleStringCopied = () => {
    setCopied(true)
  }

  const handleWebApiShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Freedsound",
          text: shareTitle,
          url: shareURL,
        })
        .then(() => {
          //console.log("Thanks for sharing!")
        })
        .catch(err => {
          //console.log(`Couldn't share because of`, err.message)
        })
    } else {
      handleClickOpen()
      //return false
    }
  }

  return (
    <div>
      <Button
        variant="text"
        className={classes.btnShare}
        size="small"
        aria-label="share"
        onClick={handleWebApiShare}
        startIcon={<ShareIcon style={{ fontSize: 14 }} />}
      >
        {t("share")}
      </Button>
      <Dialog
        maxWidth="xs"
        fullWidth
        className={classes.dialog}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-share"
      >
        <DialogTitle id="alert-dialog-share" className={classes.dialogTitle}>
          {t("share")} Freedsound
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div>
            <Grid
              container
              direction="row"
              spacing={2}
              justify="space-around"
              alignItems="center"
            >
              <Grid item>
                <EmailShareButton
                  className={classes.btnSocial}
                  url={shareURL}
                  subject={shareMailSubject}
                  body={shareTitle}
                  resetButtonStyle={false}
                  style={{ cursor: "pointer" }}
                >
                  <MailOutlineIcon style={{ color: "#999" }} />
                </EmailShareButton>
              </Grid>

              <Grid item>
                <a
                  href={`https://facebook.com/sharer/sharer.php?u=${shareURL}&quote=${shareTitle}`}
                  target="_blank"
                  rel="noreferrer"
                  aria-label=""
                  className={classes.btnSocial}
                >
                  <FacebookIcon style={{ color: "#3B65A8" }} />
                </a>
              </Grid>

              <Grid item>
                <TwitterShareButton
                  className={classes.btnSocial}
                  url={shareURL}
                  title={shareTitle}
                  resetButtonStyle={false}
                  style={{ cursor: "pointer" }}
                >
                  <TwitterIcon style={{ color: "#00A9ED" }} />
                </TwitterShareButton>
              </Grid>
              <Grid item>
                <a
                  className={classes.btnSocial}
                  onClick={checkWhatsapp}
                  href="#"
                  target="_blank"
                  rel="noopener"
                  aria-label="whatsapp"
                >
                  <WhatsAppIcon style={{ color: "#25d366" }} />
                </a>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
