import React, { useEffect } from "react"
import Button from "@material-ui/core/Button"
import DownloadIcon from "@material-ui/icons/GetApp"
import CircularProgress from "@material-ui/core/CircularProgress"
import Cookies from "js-cookie"
import { isMobile } from "react-device-detect"
import axios from "axios"

export default function Download(props) {
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(true)
  const [progress, setProgress] = React.useState("")
  const [downloadURL, setDownloadURL] = React.useState("")
  const [altIframeURL, setAltIframeURL] = React.useState("")

  useEffect(() => {
    //download start
    if (!props.id) return

    ytDownload2(props.id, props.format, props.title)
  }, [])

  /* DOWNLOAD METHOD 1 - URAMAKI */
  const ytDownloadUramaki = (id, format, title) => {
    return ytDownload4(id, format, title)

    if (!title) title = id
    if (format !== "mp3") {
      return ytDownload2(id, format, title)
    }

    console.log("uramaki..", id, format, title)

    //skip sometimes
    // if (Math.floor(Math.random() * 10) > 8) {
    //   console.log("uramaki skipped..");
    //   return ytDownload2(id, format, title);
    // }

    // 1) SET LOADING STATE
    modalDownloadReset()

    axios
      .get("https://develobot.com/uramaki/index.php?id=" + id)
      .then(res => {
        console.log("uramaki res..", res)
        res = res.data

        if (!res || res.error) {
          errorDownload("uramaki", 1, id, format, title, ytDownload2)
        } else if (res.data.url) {
          let url = res.data.url
          if (!url || url.length < 10) {
            return errorDownload("uramaki", 4, id, format, title, ytDownload2)
          }

          //success
          showDownloadBtn(url, "uramaki", format)
        } else {
          errorDownload("uramaki", 2, id, format, title, ytDownload2)
        }
      })
      .catch(err => {
        errorDownload("uramaki", 3, id, format, title, ytDownload2)
      })
  }

  /*
  *
  *
   METHOD RAPID API 1
   *
   *
   */

  const ytDownload2 = (id, format, title) => {
    if (!title) title = id
    if (format !== "mp3") return ytDownload4(id, format, title)

    return ytDownload4(id, format, title)
    

    console.log("rapidapi..", id, format)

    // 1) SET LOADING STATE
    modalDownloadReset()
    const delay = 3000
    let timesChecked = 0

    const callApi = () => {
      timesChecked++
      const params = {
        id: id,
      }
      // "https://us-central1-freedsoundmp3.cloudfunctions.net/downloadRapidApi",

      axios
        .get(
          "https://develobot.com/api/downloadRapid.php",
          {
            params,
          }
        )
        .then(res => {
          console.log('RESX', res);
          if (
            !res ||
            !res.data ||
            res.data.message === "Too many requests" ||
            res.data.status === "error" ||
            res.data.status === "fail" ||
            (timesChecked > 10 && res.progress <= 0)
          ) {
            console.log('err2x')
            return errorDownload("rapidapi", 1, id, format, title, ytDownload4)
          }
          res = res.data
          console.log("RES RAPID", timesChecked, res)

          if (!res.link) {
            setTimeout(function () {
              callApi()
            }, delay)

            setProgress(res.status + " " + res.progress + "%..")
          } else if (res.link) {
            //console.log("DURL", res.link)
            download(res.link)
          }
        })
        .catch(function (err) {
          errorDownload("rapidapi", 2, id, format, title, ytDownload4)
        })
    }

    const download = function (url) {
      showDownloadBtn(url, "rapidapi", format)
    }

    callApi()
  }

  /*
  *
  *
   METHOD RAPID API 2 - T-ONE
   *
   *
   */

  const ytDownload3 = (id, format, title) => {
    return ytDownload4(id, format, title)

    if (format !== "mp3") return ytDownload4(id, format, title)
    // if (Math.floor(Math.random() * 10) > 6)

    console.log("t-one start", id, format)

    axios
      .get(
        "https://develobot.com/rapidapi/tone/createProcess.php?format=" +
        (format === "mp4" ? "video" : format) +
        "&id=" +
        id
      )

      .then(res => {
        res = res.data
        //var res = JSON.parse(res);
        console.log("t-one res", res)

        if (!res || !res.guid) {
          return errorDownload("t-one", 1, id, format, title, ytDownload4)
        }

        //already downloaded
        if (res.message === "already downloaded" && res.file) {
          return download(res.file)
        }

        checkProgress(res.guid)
      })
      .catch(err => {
        errorDownload("t-one", 2, id, format, title, ytDownload4)
      })

    // 1) SET LOADING STATE
    modalDownloadReset()

    var timesChecked = 0
    var delay = 3000,
      download = function (url) {
        //console.log('DOWNLOAD___', url);
        if (!url) {
          return errorDownload("t-one", 3, id, format, title, ytDownload4)
        }

        console.log("send 2 cache..")

        showDownloadBtn(url, "t-one", format)

        if (format === "mp3") {
          let data = new FormData()
          data.append("id", id)
          data.append("url", url)
          data.append("name", title)
          //data.append("force", true)

          console.log("send 2 cache.. 2 ..", data)

          // axios
          //   .post("https://develobot.com/uramaki/index.php", data)
          //   .then(res => {
          //     console.log("uramaki forced", url, res)
          //   })
          //   .catch(err => {
          //     console.log("send 2 cache.. err ", err)
          //   })
        }
      },
      checkProgress = function (guid) {
        timesChecked++
        //console.log("checkprogress tone ", guid, "timesChecked", timesChecked)

        axios
          .get("https://develobot.com/rapidapi/tone/statusProcess.php", {
            params: {
              guid: guid,
            },
          })
          .then(res => {
            res = res.data
            console.log("checkprogress tone ", res, timesChecked)

            if (!res || res.status === "error") {
              return errorDownload("t-one", 4, id, format, title, ytDownload4)
            }

            /*check if is taking too long*/
            if (timesChecked > 15 && res.status === "pending") {
              return errorDownload("t-one", 5, id, format, title, ytDownload4)
            }
            /*end check */

            if (res.status === "complete" && res.file) {
              download(res.file)
            } else {
              setProgress(
                `${res.status}..  ${res.process_percentage ? res.process_percentage + "%" : ""
                }`
              )

              setTimeout(function () {
                checkProgress(guid)
              }, delay)
            }
          })
          .catch(err => {
            return errorDownload("t-one", 6, id, format, title, ytDownload4)
          })
      }
  }

  /*
  *
  *
   METHOD LOADER
   *
   *
   */
  const ytDownload4 = (id, format, title) => {
    if (!title) title = id
    if (format !== "mp3") {
      format = "1080"
    }

    //cache
    var cacheMe = false

    //var url = "http://localhost:5001/freedsoundmp3/us-central1/download"
    var url = "https://us-central1-freedsoundmp3.cloudfunctions.net/download"

    axios
      .get(url, {
        params: {
          id,
          format,
        },
      })
      .then(res => {
        //var res = JSON.parse(res);
        console.log("loader res", res)

        //uramaki success
        if (res.data && res.data.uramaki) {
          const url = res.data.uramaki
          //success
          return showDownloadBtn(url, "uramaki", format)
        }

        //not cached
        if (!res.data || !res.data.id) {
          showError()
          errorDownload("loader", 2, id, format, title)
        }

        //cache me
        if (res.data.cacheMe) cacheMe = true

        // var progress_url =
        //   "https://loader.to/ajax/progress.php?id=" + res.data.id

        var progress_url =
          "https://us-central1-freedsoundmp3.cloudfunctions.net/progress?id=" +
          res.data.id

        checkProgress(progress_url)
      })
      .catch(err => {
        console.log("fail", err)
        showError()
        errorDownload("loader", 1, id, format, title)
      })

    // 1) SET LOADING STATE
    modalDownloadReset()

    let timesChecked = 0
    let delay = 3000

    const showError = msg => {
      console.log("ERR FINAL", msg)

      setError(true)

      url =
        "https://loader.to/api/button/?url=https://www.youtube.com/watch?v=" +
        id +
        "&f=" +
        format +
        "&color=02bcd4"

      setAltIframeURL(url)

      var listener = window.addEventListener("blur", function () {
        if (
          document.activeElement ===
          document.getElementById("alt-iframe-download")
        ) {
          //https://www.onclicksuper.com/jump/next.php?r=2605255
          window.open(
            "https://nowhaphopi.com/b/3.Vu0YP/3/pEv_bHmfVtJkZ/Dv0/0rO/Djk/4eMvDuUmxnLATpQC4COOTggUwdNOT-IK"
          )
        }
        window.removeEventListener("blur", listener)
      })
    }
    const showProgress = (progress, status) => {
      if (status === null) status = "loading.."
      if (progress === null || progress === "null") progress = 0
      setProgress(status + " " + progress + "%..")
      //console.log('progress', progress);
    }

    const download = url => {
      if (!url) {
        errorDownload("loader", 2, id, format, title)
        return showError()
      }

      showDownloadBtn(url, "loader", format)

    }

    const checkProgress = url => {
      timesChecked++
      console.log("checkProgress loader xxx", url, "timesChecked", timesChecked)

      axios
        .get(url)
        .then(res => {
          //var res = JSON.parse(res);
          res = res.data
          console.log("RES1 ", res)

          if (!res) {
            console.log("RES1 MAIN ERR empty")
            errorDownload("loader", 3, id, format, title)
            return showError()
          }

          /*check if is taking too long*/
          if (
            timesChecked > 8 &&
            res.success === null &&
            (res.progress === 0 || res.progress === null) &&
            res.download_url === null
          ) {
            errorDownload("loader", 4, id, format, title)
            return showError()
          }

          if (
            timesChecked > 18 &&
            res.success === "0" &&
            (res.progress === 0 || res.progress === null) &&
            res.download_url === null
          ) {
            errorDownload("loader", 4, id, format, title)
            return showError()
          }

          if (
            timesChecked > 33 &&
            res.success === "0" &&
            res.progress === 10 &&
            res.download_url === null
          ) {
            errorDownload("loader", 4, id, format, title)
            return showError()
          }
          /*end check */

          if (
            (res.success === 1 || res.success === "1") &&
            res.progress === 1000
          ) {
            console.log("okk", 1, res)
            download(res.download_url)
          } else if (
            res.success === "0" ||
            res.success === 0 ||
            res.download_url === null
          ) {
            var progress = res.progress
            var message = "Downloading.."

            if (res.text === "Converting") {
              message = "Converting.."
            }

            if (progress > 100) progress = progress / 10

            showProgress(progress, message)
            setTimeout(function () {
              checkProgress(url)
            }, delay)
          } else if (res.success === "1" || res.download_url === null) {
            //console.log('error1', res);
            errorDownload("loader", 5, id, format, title)
            showError()
          } else {
            console.log("XXX", res)
            errorDownload("loader", 6, id, format, title)
            showError(res.success)
          }
        })
        .catch(err => {
          errorDownload("loader", 7, id, format, title)
          showError()
          console.log("CATCH ERR")
        })
    }
  }

  /*
  *
  *
   METHOD  LOADER
   *
   *
   */
  // const ytDownload4 = (id, format, title) => {
  //   if (!title) title = id
  //   if (format !== "mp3") {
  //     format = "1080"
  //   }
  //
  //   // var url =
  //   //   "https://loader.to/ajax/download.php?start=1&end=1" +
  //   //   "&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D" +
  //   //   id +
  //   //   "&format=" +
  //   //   format +
  //   //   "&apikey=6124569c108a87c06d3dc8d70ddb2669"
  //
  //   //https://us-central1-freedsoundmp3.cloudfunctions.net/download
  //   var url = "http://localhost:5001/freedsoundmp3/us-central1/download"
  //
  //   axios
  //     .get(url, {
  //       params: {
  //         id,
  //         format,
  //       },
  //     })
  //     .then(res => {
  //       //var res = JSON.parse(res);
  //       console.log("loader res", res)
  //
  //       if (!res.data || !res.data.id) {
  //         showError()
  //         errorDownload("loader", 2, id, format, title)
  //       }
  //
  //       var progress_url =
  //         "https://loader.to/ajax/progress.php?id=" + res.data.id
  //       checkProgress(progress_url)
  //     })
  //     .catch(err => {
  //       console.log("fail", err)
  //       showError()
  //       errorDownload("loader", 1, id, format, title)
  //     })
  //
  //   // 1) SET LOADING STATE
  //   modalDownloadReset()
  //
  //   let timesChecked = 0
  //   let delay = 3000
  //
  //   const showError = msg => {
  //     console.log("ERR FINAL", msg)
  //
  //     setError(true)
  //
  //     url =
  //       "https://loader.to/api/button/?url=https://www.youtube.com/watch?v=" +
  //       id +
  //       "&f=" +
  //       format +
  //       "&color=02bcd4"
  //
  //     setAltIframeURL(url)
  //
  //     var listener = window.addEventListener("blur", function () {
  //       if (
  //         document.activeElement ===
  //         document.getElementById("alt-iframe-download")
  //       ) {
  //         window.open("https://www.onclicksuper.com/jump/next.php?r=2605255")
  //       }
  //       window.removeEventListener("blur", listener)
  //     })
  //   }
  //   const showProgress = (progress, status) => {
  //     if (status === null) status = "loading.."
  //     if (progress === null || progress === "null") progress = 0
  //     setProgress(status + " " + progress + "%..")
  //     //console.log('progress', progress);
  //   }
  //
  //   const download = url => {
  //     if (!url) {
  //       errorDownload("loader", 2, id, format, title)
  //       return showError()
  //     }
  //
  //     showDownloadBtn(url, "loader", format)
  //
  //     //uramaki post
  //     // if (format == "mp3") {
  //     //   $.post("https://develobot.com/uramaki/index.php", {
  //     //     id: id,
  //     //     url: url,
  //     //     name: title,
  //     //     force: true
  //     //   }).done(function(res) {
  //     //     console.log("uramaki", res);
  //     //   });
  //     // }
  //   }
  //
  //   const checkProgress = url => {
  //     timesChecked++
  //     console.log("checkProgress loader xxx", url, "timesChecked", timesChecked)
  //
  //     axios
  //       .get(url)
  //       .then(res => {
  //         //var res = JSON.parse(res);
  //         res = res.data
  //         console.log("RES1 ", res)
  //
  //         if (!res) {
  //           console.log("RES1 MAIN ERR empty")
  //           errorDownload("loader", 3, id, format, title)
  //           return showError()
  //         }
  //
  //         /*check if is taking too long*/
  //         if (
  //           timesChecked > 8 &&
  //           res.success === null &&
  //           (res.progress === 0 || res.progress === null) &&
  //           res.download_url === null
  //         ) {
  //           errorDownload("loader", 4, id, format, title)
  //           return showError()
  //         }
  //
  //         if (
  //           timesChecked > 18 &&
  //           res.success === "0" &&
  //           (res.progress === 0 || res.progress === null) &&
  //           res.download_url === null
  //         ) {
  //           errorDownload("loader", 4, id, format, title)
  //           return showError()
  //         }
  //
  //         if (
  //           timesChecked > 33 &&
  //           res.success === "0" &&
  //           res.progress === 10 &&
  //           res.download_url === null
  //         ) {
  //           errorDownload("loader", 4, id, format, title)
  //           return showError()
  //         }
  //         /*end check */
  //
  //         if (res.success === "1" && res.progress === 1000) {
  //           download(res.download_url)
  //         } else if (res.success === "0" || res.download_url === null) {
  //           var progress = res.progress
  //           var message = "Downloading.."
  //
  //           if (res.text === "Converting") {
  //             message = "Converting.."
  //           }
  //
  //           if (progress > 100) progress = progress / 10
  //
  //           showProgress(progress, message)
  //           setTimeout(function () {
  //             checkProgress(url)
  //           }, delay)
  //         } else if (res.success === "1" || res.download_url === null) {
  //           //console.log('error1', res);
  //           errorDownload("loader", 5, id, format, title)
  //           showError()
  //         } else {
  //           errorDownload("loader", 6, id, format, title)
  //           showError(res.success)
  //         }
  //       })
  //       .catch(err => {
  //         errorDownload("loader", 7, id, format, title)
  //         showError()
  //         console.log("CATCH ERR")
  //       })
  //   }
  // }

  // Download clickEvent pop

  const onDownloadBtnClick = () => {
    typeof window !== "undefined" &&
      window.gtag &&
      window.gtag("event", "Btn Click", {
        event_category: "Download Btn Click",
        event_label: "btn click",
      })

    let downloadsCount = parseInt(Cookies.get("downloadsCount"))
    if (!downloadsCount) {
      Cookies.set("downloadsCount", 1)
    } else {
      Cookies.set("downloadsCount", ++downloadsCount)
    }
    if (!isMobile || downloadsCount > 1) {
      window.open(
        "https://nowhaphopi.com/b/3.Vu0YP/3/pEv_bHmfVtJkZ/Dv0/0rO/Djk/4eMvDuUmxnLATpQC4COOTggUwdNOT-IK"
      )

    }
  }

  /*
   *
   * common events
   *
   */

  const modalDownloadReset = () => {
    setLoading(true)
    setError(false)
    setProgress("")
  }

  /*
   * conversion error
   */
  const errorDownload = (method, errorNumber, id, format, title, callback) => {
    console.log("errorDownload", method, errorNumber)
    typeof window !== "undefined" &&
      window.gtag &&
      window.gtag("event", errorNumber + "_" + format, {
        event_category: "Error Download",
        event_label: method,
      })

    if (callback) {
      return callback(id, format, title)
    }
  }

  /*
   * download success
   */
  const showDownloadBtn = (url, method, format) => {
    //onsole.log("downloadSuccess", method, format)
    setLoading(false)
    setDownloadURL(url)
  }

  return (
    <div>
      {loading && !error && (
        <div style={{ textAlign: "center" }}>
          <CircularProgress color="primary" />
        </div>
      )}

      {progress && !error && !downloadURL && (
        <div>
          <span
            style={{
              paddingBottom: 5,
              paddingTop: 5,
              display: "block",
              fontSize: 14,
              color: "#5b5b5b",
            }}
          >
            {progress}
          </span>
        </div>
      )}

      {!loading && downloadURL && (
        <div>
          <Button
            href={downloadURL}
            fullWidth
            onClick={onDownloadBtnClick}
            variant="contained"
            color="primary"
            format={props.format}
            id={props.id}
            size="large"
            style={{ padding: 13, fontWeight: "bold" }}
            startIcon={<DownloadIcon />}
          >
            Download {props.format}
          </Button>
        </div>
      )}

      {error && (
        <div>
          {altIframeURL && (
            <iframe
              title="alt-iframe-download"
              id="alt-iframe-download"
              src={altIframeURL}
              width="400"
              height="70"
              scrolling="no"
              style={{ width: "100%", border: 0 }}
            ></iframe>
          )}
        </div>
      )}
    </div>
  )
}
